import { PanelPlugin } from '@grafana/data';
import { SimpleOptions } from './types';
import { SimplePanel } from './SimplePanel';

export const plugin = new PanelPlugin<SimpleOptions>(SimplePanel).setPanelOptions((builder) => {
  return builder
    .addNumberInput({
      path: 'rpmMin',
      name: 'RPM Min',
      defaultValue: 50,
    })
    .addNumberInput({
      path: 'rpmMax',
      name: 'RPM Max',
      defaultValue: 60,
    })
    .addNumberInput({
      path: 'accelMin',
      name: 'Accel Min',
      defaultValue: 12,
    })
    .addNumberInput({
      path: 'accelMax',
      name: 'Accel Max',
      defaultValue: 20,
    })
    .addNumberInput({
      path: 'durationMin',
      name: 'Duration Min',
      defaultValue: 3,
    })
    .addNumberInput({
      path: 'durationMax',
      name: 'Duration Max',
      defaultValue: 10,
    });
});
