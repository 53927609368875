import React, { useRef, useEffect, useState } from 'react';
import { PanelProps } from '@grafana/data';
import { SimpleOptions } from 'types';
import { Button, Slider, InlineField, Input, Card, Tag, Field } from '@grafana/ui';
import axios from 'axios';
import swal from 'sweetalert';

interface Props extends PanelProps<SimpleOptions> {}

export const SimplePanel: React.FC<Props> = ({ options, data, width, height }) => {
  //let pluginId = useRef(0);
  //const [pluginId, setPluginId] = useState(999);
  const [userVelocity, setUserVelocity] = useState(4);
  const [userDuration, setUserDuration] = useState(2);
  // eslint-disable-next-line
  const [userAcceleration, setUserAcceleration] = useState(0.05);
  //local storage comment
  //const [username, setUsername] = useState('');
  const [username, setUsername] = useState(localStorage.getItem('username') || '');
  //local storage comment-

  const [contact, setContact] = useState('');
  const [email, setEmail] = useState('');
  const [formDisplay, setFormDisplay] = useState(0); // 0=new user form, 1=waiting form, 2=spin form
  const [userQueue, setUserQueue] = useState<string[]>([]);
  const pluginId = useRef(99999);

  useEffect(() => {
    axios
      .get('api/datasources/id/wheel-backend')
      .then((res) => {
        pluginId.current = res.data.id;
        console.log('id once: ' + res.data.id);
        axios.get('api/datasources/' + pluginId.current + '/resources/allPlayers').then((res2) => {
          if (res2.data != null) {
            setUserQueue(res2.data);
            if (username === '') {
              setFormDisplay(0);
            } else if (res2.data.includes(username)) {
              setFormDisplay(1);
            } else {
              setFormDisplay(3);
              localStorage.clear();
              setUsername('');
            }
          } else {
            localStorage.clear();
            setUsername('');
            setFormDisplay(0);
          }
        });
      })
      .catch((err) => {
        console.log(err);
        swal('Error', 'Could not connect to backend', 'error');
      });

    //var cookies = document.cookie.split(';');
    //local storage comment
    //let cookieUsername = getCookie('username');
    //local storage comment-

    // axios.get('api/datasources/' + pluginId.current + '/resources/allPlayers').then((res) => {
    //   if (res.data != null) {
    //     setUserQueue(res.data);
    //   }
    // });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    localStorage.setItem('username', username);
  }, [username]);

  useEffect(() => {
    const interval = setInterval(() => {
      console.log('plugin idp: ' + pluginId.current);
      console.log('username: ' + username);
      //check if cookie is set

      //let cookieUsername = document.cookie.split(';')[0].split('username=')[1];
      //local storage comment
      // let cookieUsername = getCookie('username');
      //console.log('checking cookie: ' + cookieUsername);
      //local storage comment-
      axios
        .get('api/datasources/' + pluginId.current + '/resources/player')
        .then(function (response: any) {
          console.log('response: ', response);
          //if no cookie is set
          if (username === '') {
            setFormDisplay(0);
            //if stored name is the same as player at top of queue
          } else if (username === response.data) {
            setFormDisplay(2);
            //cookie is set but player not at top of queue
          } else {
            setFormDisplay(1);
          }
        })
        .catch(function (error: any) {
          setFormDisplay(0);
        });
    }, 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [formDisplay]);

  useEffect(() => {
    console.log('checking queue');
    const interval = setInterval(() => {
      axios.get('api/datasources/' + pluginId.current + '/resources/allPlayers').then((res) => {
        if (res.data != null) {
          setUserQueue(res.data);
        }
      });
    }, 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [userQueue]);

  // const getPlayer = () => {
  //   let interpolatedUrl = 'api/datasources/' + pluginId.current + '/resources/player';
  //   axios.get(interpolatedUrl).then((res) => {
  //     console.log(res.data);
  //   });
  // };

  // const allPlayers = () => {
  //   let interpolatedUrl = 'api/datasources/' + pluginId.current + '/resources/allPlayers';
  //   axios.get(interpolatedUrl).then((res) => {
  //     console.log(res.data);
  //   });
  // };

  const newPlayerSubmit = () => {
    let interpolatedUrl = 'api/datasources/' + pluginId.current + '/resources/newPlayer';
    axios
      .post(interpolatedUrl, {
        username: username,
        contact: contact,
        email: email,
      })
      .then((res) => {
        console.log(res.data);
        //local storage comment
        //document.cookie = 'username=' + username + '; ' + 'Path=/' + '; ' + 'SameSite=Lax';
        //local storage comment-
        setFormDisplay(1);
      });
  };

  // const readCookie = () => {
  //   let username = document.cookie.split(';')[0].split('=')[1];
  //   console.log(username);
  // };

  const spinForm = () => {
    return (
      <>
        <InlineField label="Top Speed (RPM)" grow labelWidth={20}>
          <Slider
            min={options.rpmMin}
            max={options.rpmMax}
            step={1}
            onChange={(value) => {
              setUserVelocity(value);
            }}
          />
        </InlineField>
        <InlineField label="Acceleration (RP/M^2)" grow labelWidth={20}>
          <Slider
            // eslint-disable-next-line
            min={options.accelMin}
            // eslint-disable-next-line
            max={options.accelMax}
            // eslint-disable-next-line
            step={1}
            onChange={(value) => {
              setUserAcceleration(value / 60);
            }}
          />
        </InlineField>

        <InlineField label="Top Speed Duration (s)" grow labelWidth={20}>
          <Slider
            min={options.durationMin}
            max={options.durationMax}
            onChange={(value) => {
              setUserDuration(value);
            }}
          />
        </InlineField>
        {spinButton()}
      </>
    );
  };

  // const clearCookieButton = () => {
  //   return (
  //     <Button
  //       onClick={() => {
  //         document.cookie = 'username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  //         setFormDisplay(0);
  //       }}
  //     >
  //       Clear Cookie
  //     </Button>
  //   );
  // };

  const spinButton = () => {
    return (
      <>
        <Button
          fullWidth={true}
          style={{ width: '100%' }}
          onClick={() => {
            let interpolatedUrl = 'api/datasources/' + pluginId.current + '/resources/spin';
            axios
              .post(interpolatedUrl, {
                username: username,
                velocity: userVelocity.toString(),
                acceleration: userAcceleration.toString(),
                duration: userDuration.toString(),
              })
              .then((res) => {
                console.log('removing cookie');
                //clear cookie to reset to new user page, dont clear to disallow multiple spins
                // document.cookie = 'username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
                localStorage.clear();
                setUsername('');
                setFormDisplay(0);
                setContact('');
                setEmail('');
                swal('Success!', 'Spin recieved, thank you for spinning!', 'success');
              })
              .catch(function (error) {
                localStorage.clear();
                setUsername('');
                setFormDisplay(0);
                setContact('');
                setEmail('');
                swal('Error', 'Please try again', 'error');
              });
          }}
        >
          Spin
        </Button>
      </>
    );
  };

  const waitingPage = () => {
    // let cookieUsername = document.cookie.split(';')[0].split('username=')[1];
    // return (
    //   <Card>
    //     <Card.Heading>{cookieUsername}</Card.Heading>
    //   </Card>
    // );
    return userQueue.map((player) => {
      return (
        // eslint-disable-next-line
        <div>
          <Card>
            <Card.Heading>{player}</Card.Heading>
            <Card.Tags>
              {/* <Tag name={x.toString()}/> */}
              <Tag name={userQueue.indexOf(player).toString()} />
            </Card.Tags>
          </Card>
        </div>
      );
    });

    // return(
    // <div>
    //   in queue
    // </div>
    // )
  };

  const errorPage = () => {
    return <div>Your username has been set, put you do not appear in the queue, please try again.</div>;
  };

  const thankYouPage = () => {
    return <div>Thank you for spinning!</div>;
  };

  const newUserForm = () => {
    return (
      <>
        <Field invalid={username === ''} error={username === '' ? 'This input is required' : ''} label={'Full Name'}>
          <Input onChange={(e) => setUsername(e.currentTarget.value)} />
        </Field>
        <Field invalid={contact === ''} error={contact === '' ? 'This input is required' : ''} label={'Phone #'}>
          <Input onChange={(e) => setContact(e.currentTarget.value)} />
        </Field>
        <Field invalid={email === ''} error={email === '' ? 'This input is required' : ''} label={'Email'}>
          <Input onChange={(e) => setEmail(e.currentTarget.value)} />
        </Field>
        {/* <InlineField label="Username" grow labelWidth={12}>
          <Input onChange={(e) => setUsername(e.currentTarget.value)} />
        </InlineField> */}
        {/* <InlineField label="Contact" grow labelWidth={12}>
          <Input onChange={(e) => setContact(e.currentTarget.value)} />
        </InlineField> */}
        <Button fullWidth={true} onClick={newPlayerSubmit} style={{ width: '100%', margin: 'auto' }}>
          Submit
        </Button>
      </>
    );
  };

  const displayPage = () => {
    if (formDisplay === 0) {
      return newUserForm();
    } else if (formDisplay === 1) {
      return waitingPage();
    } else if (formDisplay === 2) {
      return spinForm();
    } else if (formDisplay === 3) {
      return errorPage();
    } else {
      return thankYouPage();
    }
  };

  return (
    <>
      {/* <Button className="powerOn" fullWidth={true} variant={'destructive'} icon={'power'} onClick={getPlayer}>
        Get Player
      </Button>
      <Button className="powerOn" fullWidth={true} variant={'destructive'} icon={'power'} onClick={readCookie}>
        Read Cookie
      </Button>
      <Button className="powerOn" fullWidth={true} variant={'destructive'} icon={'power'} onClick={allPlayers}>
        All Players
      </Button>
      <Button className="powerOn" fullWidth={true} variant={'destructive'} icon={'power'} onClick={newPlayerSubmit}>
        New Player
      </Button>
      {clearCookieButton()} */}
      {displayPage()}

      {/* <div>{spinForm()}</div>
      <div>{newUserForm()}</div> */}
    </>
  );
};
